
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetSlideReviews();
        App.SetCarouselMobile();
        App.SetHeaderFixed(80);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(90);
        App.SetWow(200);

        $('html').addClass('loaded');
        
        $('body').on('click', '.menuBlocker', function() {
            $('body').toggleClass('_toggled');
        });

        $('body').on('click', '.Header .menu a', function() {
            $('body').removeClass('_toggled');
        });
    });
});


